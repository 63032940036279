import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayout,
} from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import axios from "axios";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { Home } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SupportIcon from "@mui/icons-material/Support";
import dataProvider from "@refinedev/simple-rest";
import DashboardPage from "pages/dashboard";
//import MarketComparisionPage from "pages/marketComparision";
import { Login } from "pages/login";
import { useTranslation } from "react-i18next";
import { BrowserRouter, HashRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { Header } from "./components/header";
import { CustomSider } from "components/sider";
import ManageWines from "pages/manageWines";
import UploadEvaluation from "pages/uploadEvaluation";
import "./style/global.css";
import Support from "pages/support";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTokenFromLocalCookie } from "common/CommonService";
import UploadCsv from "pages/uploadCsv";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: any) => {
  const token = getTokenFromLocalCookie();
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});

function App() {
  const { t, i18n } = useTranslation();

  const dataProviderUrl: string = (localStorage.getItem("v2") === "true" ? process.env.REACT_APP_BASE_URL_NEW : process.env.REACT_APP_BASE_URL) + "api";

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <HashRouter>
      <ToastContainer />
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineKbarProvider>
        <RefineSnackbarProvider>
          <Refine
            dataProvider={dataProvider(dataProviderUrl)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: "dashboard",
                list: "/dashboard",
                meta: {
                  label: t("dashboard.title", "Dashboard"),
                  icon: <Home />,
                },
              },
              // {
              //   name: "market-comparison",
              //   list: "/marketComparision",
              //   meta: {
              //     label: t("marketComparison", "Market Comparison"),
              //     icon: <BarChartIcon />,
              //   },
              // },
              {
                name: "manage-wine",
                list: "/manage-wine",
                meta: {
                  label: t("manageWines", "Manage Wines"),
                  icon: <AssignmentIcon />,
                },
              },
              // {
              //   name: "upload-evaluation",
              //   list: "/upload-evaluation",
              //   meta: {
              //     label: t("uploadEvaluation", "Upload Evaluation"),
              //     icon: <FolderOpenIcon />,
              //   },
              // },
              // {
              //   name: "upload-data",
              //   list: "/upload-data",
              //   meta: {
              //     label: t("uploadData", "Upload Data"),
              //     icon: <FolderOpenIcon />,
              //   },
              // },
              {
                name: "support",
                list: "/support",
                meta: {
                  label: t("support", "Support"),
                  icon: <SupportIcon />,
                },
              },
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />} key={1}>
                    <ThemedLayout Header={Header} Sider={CustomSider}>
                      <Outlet />
                    </ThemedLayout>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<NavigateToResource resource="dashboard" />}
                />

                <Route path="/dashboard" element={<DashboardPage />} />
                {/* In future we can enable below link */}
                {/* <Route path="/marketComparision" element={<MarketComparisionPage />} /> */}
                <Route path="/manage-wine" element={<ManageWines />} />
                <Route
                  path="/upload-evaluation"
                  element={<UploadEvaluation />}
                />
                <Route
                  path="/upload-data"
                  element={<UploadCsv />}
                />
                <Route
                  path="/support"
                  element={<Support openDialog={true} />}
                />
              </Route>

              <Route
                element={
                  <Authenticated fallback={<Outlet />} key={2}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
              <Route
                element={
                  <Authenticated key={3}>
                    <ThemedLayout Header={Header} Sider={CustomSider}>
                      <Outlet />
                    </ThemedLayout>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </RefineSnackbarProvider>
      </RefineKbarProvider>
    </HashRouter>
  );
}

export default App;